import ClientListItem from "@/components/Layout/ClientListItem.tsx";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import type { ListClient } from "@/queries/client.ts";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import SelfAssign from "./SelfAssign.tsx";

type Props = {
    dialogProps: ControlledDialogProps;
    clients: ListClient[];
    selfAssignAllowed: boolean;
};

const ClientSwitcherDialog = ({ dialogProps, clients, selfAssignAllowed }: Props): ReactNode => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const filterRef = useRef<HTMLInputElement>(null);

    const filteredClients = useMemo(() => {
        if (filter === "") {
            return clients;
        }

        const normalizedFilter = filter.toLowerCase();
        return clients.filter((client) =>
            client.name.toLocaleLowerCase().includes(normalizedFilter),
        );
    }, [clients, filter]);

    useEffect(() => {
        const focusFilterInput = (event: KeyboardEvent) => {
            if (!((event.ctrlKey || event.metaKey) && event.key === "f")) {
                return;
            }

            if (!(filterRef.current && dialogProps.open)) {
                return;
            }

            if (document.activeElement === filterRef.current) {
                return;
            }

            event.preventDefault();
            filterRef.current.focus();
        };

        window.addEventListener("keydown", focusFilterInput);

        return () => {
            window.removeEventListener("keydown", focusFilterInput);
        };
    }, [dialogProps.open]);

    return (
        <Dialog {...dialogProps} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                    <Typography variant="inherit" sx={{ whiteSpace: "nowrap" }}>
                        Switch client
                    </Typography>
                    {clients.length > 10 && (
                        <TextField
                            label="Filter"
                            size="small"
                            fullWidth
                            value={filter}
                            onChange={(event) => {
                                setFilter(event.target.value);
                            }}
                            inputRef={filterRef}
                        />
                    )}
                </Stack>
            </DialogTitle>
            <DialogContent dividers sx={{ px: 0, py: 1 }}>
                <List dense disablePadding>
                    {filteredClients.map((client) => (
                        <ClientListItem
                            key={client.id}
                            client={client}
                            onSelect={() => {
                                void navigate({
                                    to: "/$clientId",
                                    params: { clientId: client.id },
                                });
                                dialogProps.onClose();
                            }}
                            selfAssignAllowed={selfAssignAllowed}
                        />
                    ))}

                    {filteredClients.length === 0 && (
                        <ListItem>
                            <ListItemText>No client matches your filter</ListItemText>
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            {selfAssignAllowed && (
                <Box
                    sx={{
                        py: 1,
                        px: 2,
                    }}
                >
                    <SelfAssign />
                </Box>
            )}
        </Dialog>
    );
};
export default ClientSwitcherDialog;
