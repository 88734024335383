import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { awsAccountRelationship, awsRegionRelationship } from "@/queries/common.ts";
import { apiUrl } from "@/utils/api.ts";
import { ZoneId } from "@js-joda/core";
import { queryOptions } from "@tanstack/react-query";
import {
    type Relationships,
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z.object({
    name: z.string().min(1),
    domainName: z.string().min(1),
    instanceType: z.string().min(1),
    status: z.enum([
        "estimate",
        "staging",
        "queued",
        "active",
        "standby",
        "suspended",
        "stopped",
        "halted",
        "updating",
        "launching",
        "decommissioned",
        "terminated",
        "error",
    ]),
    agreement: z
        .object({
            pdfObjectKey: z.string().min(1),
            signature: z.string().min(1).nullable(),
        })
        .optional(),
    licenses: z
        .object({
            fms: z.enum(["buy", "byol"]).nullable().optional(),
        })
        .optional(),
    timezone: z.string().transform((value) => ZoneId.of(value)),
    operatingSystem: z.object({
        name: z.string().min(1),
        version: z.string().min(1),
    }),
    remoteAccessRequest: z.enum(["allowed", "pending", "disallowed"]),
    state: z.object({
        current: z.enum(["running", "stopped", "stopping", "starting", "standby"]),
        lastBootedAt: zj.zonedDateTime().nullable(),
        actions: z.array(z.enum(["start", "stop", "restart"])).transform((value) => new Set(value)),
    }),
    capabilities: z
        .array(
            z.enum([
                "disaster_recovery",
                "dynamic_docket",
                "event_schedule",
                "filemaker_server",
                "private_backup",
                "snapback",
                "volumes",
                "webdirect_auto_scaling",
            ]),
        )
        .transform((value) => new Set(value)),
    datadogDashboardUrl: z.string().url().nullable(),
});

const relationships = {
    account: awsAccountRelationship,
    region: awsRegionRelationship,
} satisfies Relationships;

const hostsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "host",
        attributesSchema: attributesSchema.omit({
            timezone: true,
            operatingSystem: true,
            remoteAccessRequest: true,
            state: true,
            capabilities: true,
            datadogDashboardUrl: true,
        }),
        relationships,
    }),
);
const hostSelector = createDataSelector(
    createResourceSelector({
        type: "host",
        attributesSchema,
        relationships,
    }),
);

export type ListHost = ReturnType<typeof hostsSelector>[number];
export type Host = ReturnType<typeof hostSelector>;

type InferSetValues<T> = T extends Set<infer U> ? U : never;
export type Capability = InferSetValues<Host["capabilities"]>;

export const createHostQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    list: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "hosts"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return hostsSelector(await response.json());
            },
        }),
    get: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts/${hostId}`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return hostSelector(await response.json());
            },
        }),
});
