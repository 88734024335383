import type { DialogController } from "@/hooks/useDialogController.tsx";
import { useDeleteSetupIntentMutation } from "@/mutations/setup-intent.ts";
import type { StripeSetupIntent } from "@/queries/stripe.ts";
import { dateFormatter } from "@/utils/format.ts";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";

type Props = {
    clientId: string;
    setupIntent: StripeSetupIntent;
    setupDialogController: DialogController;
    refresh: () => void;
    isRefreshing: boolean;
};

const SetupIntentCard = ({
    clientId,
    setupIntent,
    setupDialogController,
    refresh,
    isRefreshing,
}: Props): ReactNode => {
    const deleteSetupIntentMutation = useDeleteSetupIntentMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    let content: ReactNode;
    let actions: ReactNode;

    const refreshButton = (
        <LoadingButton size="small" loading={isRefreshing} onClick={refresh}>
            Refresh
        </LoadingButton>
    );

    const cancelButton = (
        <LoadingButton
            size="small"
            color="error"
            loading={deleteSetupIntentMutation.isPending}
            onClick={() => {
                deleteSetupIntentMutation.mutate(undefined, {
                    onError: () => {
                        enqueueSnackbar("Failed to cancel setup", { variant: "error" });
                    },
                });
            }}
        >
            Cancel
        </LoadingButton>
    );

    switch (setupIntent.status) {
        case "processing": {
            content = (
                <Typography>
                    Hold tight, we are setting up your payment method for future payments. We'll
                    email you when your payment method has been successfully set up.
                </Typography>
            );
            break;
        }

        case "canceled": {
            content = <Typography>The setup has been canceled.</Typography>;
            actions = <CardActions>{refreshButton}</CardActions>;
            break;
        }

        case "succeeded": {
            content = (
                <Typography>
                    The setup has succeeded and we will display your payment method momentarily.
                </Typography>
            );
            actions = <CardActions>{refreshButton}</CardActions>;
            break;
        }

        case "requires_action": {
            if (setupIntent.nextAction?.type === "verify_with_microdeposits") {
                content = (
                    <Typography>
                        You will received a micro deposit on your account around{" "}
                        {setupIntent.nextAction.arrivalDate.format(dateFormatter)}. Once received,
                        please verify your account with the code included in the statement.
                    </Typography>
                );
                actions = (
                    <CardActions>
                        <Button
                            href={setupIntent.nextAction.hostedVerificationUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Verify account
                        </Button>
                        {cancelButton}
                    </CardActions>
                );
                break;
            }

            content = <Typography>The setup requires further action from you.</Typography>;
            actions = <CardActions>{cancelButton}</CardActions>;
            break;
        }

        case "requires_confirmation": {
            content = <Typography>We are confirming your payment method right now.</Typography>;
            actions = <CardActions>{refreshButton}</CardActions>;
            break;
        }

        case "requires_payment_method": {
            content = <Typography>Setup of a new payment method has been started.</Typography>;
            actions = (
                <CardActions>
                    <Button
                        size="small"
                        onClick={() => {
                            setupDialogController.open();
                        }}
                    >
                        Continue
                    </Button>
                    {cancelButton}
                </CardActions>
            );
            break;
        }
    }

    return (
        <Card sx={{ flexGrow: 1, minWidth: "50%" }}>
            <CardHeader title="Payment Setup" />
            <CardContent>{content}</CardContent>
            {actions}
        </Card>
    );
};

export default SetupIntentCard;
