import CardField from "@/components/CardField";
import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import { Box, Button, Grid2, Paper, Stack } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import RemoteAccessButton from "./-components/RemoteAccessButton.tsx";
import StateActions from "./-components/StateActions.tsx";
import StateCardField from "./-components/StateCardField.tsx";
import UptimeCardField from "./-components/UptimeCardField.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: host } = useSuspenseQuery(qof.host.get(clientId, params.hostId));
    const { zoneId } = useTimeZoneContext();

    return (
        <>
            <Stack
                spacing={2}
                direction={{ xs: "column", lg: "row" }}
                sx={{
                    mb: 2,
                    alignItems: "flex-start",
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <StateActions host={host} />
                </Box>
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    {host.datadogDashboardUrl && (
                        <Button
                            variant="outlined"
                            href={host.datadogDashboardUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Datadog Dashboard
                        </Button>
                    )}
                    <RemoteAccessButton host={host} />
                </Stack>
            </Stack>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Paper sx={{ p: 2, height: "100%" }}>
                        <Stack
                            sx={{
                                gap: 2,
                            }}
                        >
                            <CardField label="Domain name" value={host.domainName} />
                            <CardField label="Instance type" value={host.instanceType} />
                            <CardField label="Account" value={host.account.name} />
                            <CardField label="Region" value={host.region.name} />
                            <CardField
                                label="Operating system"
                                value={
                                    <>
                                        {host.operatingSystem.name}
                                        <br />
                                        Version: {host.operatingSystem.version}
                                    </>
                                }
                            />
                            <CardField label="Time zone" value={host.timezone.toString()} />
                        </Stack>
                    </Paper>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Paper sx={{ p: 2, height: "100%" }}>
                        <Stack
                            sx={{
                                gap: 2,
                            }}
                        >
                            <StateCardField state={host.state} />
                            {host.state.lastBootedAt && (
                                <>
                                    <CardField
                                        label="Launch time"
                                        value={host.state.lastBootedAt
                                            .withZoneSameInstant(zoneId)
                                            .format(zonedDateTimeFormatter)}
                                    />

                                    {host.state.current === "running" && (
                                        <UptimeCardField lastBootedAt={host.state.lastBootedAt} />
                                    )}
                                </>
                            )}
                        </Stack>
                    </Paper>
                </Grid2>
            </Grid2>
        </>
    );
};
export const Route = createFileRoute("/$clientId/hosts/$hostId/")({
    component: Root,
});
